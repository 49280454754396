import {
  AdminPanelSettings,
  AlternateEmail,
  BarChart,
  Book,
  Business,
  CalendarMonth,
  CardGiftcard,
  ChangeHistory,
  Compare,
  CoPresent,
  DarkMode,
  DesktopMac,
  Favorite,
  FilterCenterFocus,
  Flag,
  Groups2,
  Home,
  JoinFull,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LightMode,
  Link as LinkIcon,
  LocalFireDepartment,
  LocalOffer,
  Login,
  Logout,
  Loyalty,
  Map as MapIcon,
  MilitaryTech,
  MonetizationOn,
  Newspaper,
  Paid,
  Public,
  Reviews,
  RocketLaunch,
  Science,
  Search,
  Settings,
  Shield,
  ShieldMoon,
  Speed,
  Tag,
  TipsAndUpdates,
  Topic,
  TrendingUp,
  Twitter,
  VideogameAsset,
  ViewWeek,
  Visibility,
  Whatshot,
} from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Popover,
  Stack,
  styled,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link, navigate } from "gatsby";
import type { Action } from "kbar";
import { Priority, useRegisterActions } from "kbar";
import { isEqual } from "lodash";
import type { ReactNode } from "react";
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { match } from "ts-pattern";

import emojiAprilFools from "../../../assets/emoji/april-fools.png";
import emojiEarthDay from "../../../assets/emoji/earth-day.png";
import emojiHalloween from "../../../assets/emoji/halloween.png";
import emojiMario from "../../../assets/emoji/mario.png";
import emojiNewYear from "../../../assets/emoji/new-year.png";
import emojiPride from "../../../assets/emoji/pride.png";
import emojiThanksgiving from "../../../assets/emoji/thanksgiving.png";
import emojiValentines from "../../../assets/emoji/valentines.png";
import emojiWorkersDay from "../../../assets/emoji/workers-day.png";
import emojiXmas from "../../../assets/emoji/xmas.png";
import { useColorMode } from "../../../context/ColorModeContext";
import SearchBoxPlus from "../../table/filter/SearchBoxPlus";
import FakeRolesDialog, { storeFakeRoles } from "../display/FakeRolesDialog";
import MuiComponentLink from "../display/MuiComponentLink";
import { MutedLink } from "../display/MutedLink";
import {
  AppleIcon,
  DiscordIcon,
  EpicIcon,
  MetaIcon,
  NintendoIcon,
  PlaystationIcon,
  SteamIcon,
  XboxIcon,
} from "../icon/SvgIcon";
import { th } from "../pro-theme";
import { jsxJoin } from "../util/ReactUtils";

import { CMD_K } from "./CommandKMenu";
import { getFullWidth } from "./DashboardLayout";
import { useNavigationState } from "./NavigationStateContext";

import logo from "@gdco/fe-core/assets/branded-gdco/gamediscoverco-logo-small.png";
import AnyLink from "@gdco/fe-core/components/util/AnyLink";
import ExternalLink from "@gdco/fe-core/components/util/ExternalLink";
import { useUser } from "@gdco/fe-core/context/UserContext";
import type { User } from "@gdco/fe-core/context/UserContext";
import {
  linkTo,
  routeInternal,
  routeInternalPro,
} from "@gdco/fe-core/fetch/route-internal";
import { featureRoles, ROLE_DEFS, roleParams } from "@gdco/fe-core/roles";
import { baseUrlProduction } from "@gdco/fe-core/util/base-url";
import {
  isBrowserEnvironment,
  isProDomain,
  ProNavigationState,
  subdomainName,
} from "@gdco/fe-core/util/config";
import {
  gdcoSessionStorageData,
  gdcoStorage,
} from "@gdco/fe-core/util/storage";

const SidePanel = () => {
  const navState = useNavigationState();
  const { colorMode, setColorMode } = useColorMode();
  const theme = useTheme();

  const user = useUser();
  const isRealAdmin = user.hasAnyRole(ROLE_DEFS.admin, {
    ignoreFakeRoles: true,
  });

  const [searchAnchorEl, setSearchAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [isFakeRolesDialogOpen, setIsFakeRolesDialogOpen] = useState(false);

  const isFullSidebar = navState.navigation === ProNavigationState.FULL;

  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const storageScrollTop = gdcoStorage.session.getItem(
      gdcoSessionStorageData.sidePanelScrollTop.keyName,
    );
    scrollRef.current?.scrollTo({ top: Number(storageScrollTop) });
  }, []);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop } = scrollRef.current;
      gdcoStorage.session.setItem(
        gdcoSessionStorageData.sidePanelScrollTop.keyName,
        scrollTop.toString(),
      );
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      gdcoStorage.session.removeItem(
        gdcoSessionStorageData.sidePanelScrollTop.keyName,
      );
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const searchBorderColor =
    theme.palette.mode === "dark"
      ? theme.palette.nav.main
      : theme.palette.primary.main;

  const menuSections = useMemo(() => {
    const sections = getMenuSections(user);

    const filteredSections = sections
      .map((section) => {
        if (section.enabled === false) {
          return null;
        }

        const enabledItems = section.items.filter(
          (item) => item.enabled !== false,
        );

        if (enabledItems.length === 0) {
          return null;
        }

        return {
          ...section,
          items: enabledItems,
        };
      })
      .filter((section): section is MenuSection => section !== null);

    return filteredSections;
  }, [user]);

  const commandMenuActions: Action[] = useMemo(() => {
    const actions: Action[] = [];

    for (const section of menuSections) {
      const sectionTitle = section.title ?? "Main";

      for (const item of section.items) {
        actions.push({
          id: item.to,
          name: item.title,
          icon: item.icon,
          keywords: item.shortTitle ?? item.title,
          section: `Navigation: ${sectionTitle}`,
          perform: () => {
            void navigate(item.to);
          },
        });
      }
    }

    actions.push(
      {
        id: "toggle-sidebar",
        parent: CMD_K.parents.preferences,
        name: isFullSidebar ? "Collapse Sidebar" : "Expand Sidebar",
        keywords: "toggle sidebar menu",
        icon: isFullSidebar ? (
          <KeyboardDoubleArrowLeft />
        ) : (
          <KeyboardDoubleArrowRight />
        ),
        perform: () => {
          navState.toggleNavigation();
        },
      },
      {
        id: "external",
        name: "External Links",
        icon: <LinkIcon />,
        section: CMD_K.sections.site,
      },
      {
        id: "external-company-site",
        parent: "external",
        name: "Company Site",
        keywords: "company gdco gamediscoverco",
        icon: <Public />,
        perform: () => {
          window.open(linkTo.companyDomain, "_blank");
        },
      },
      {
        id: "external-newsletter",
        parent: "external",
        name: "Newsletter",
        keywords: "newsletter",
        icon: <Newspaper />,
        perform: () => {
          window.open(linkTo.newsletterDomain, "_blank");
        },
      },
      {
        id: "external-contact-us",
        parent: "external",
        name: "Contact Us",
        subtitle: "Send feedback or questions",
        keywords: "email feedback simon carless",
        icon: <AlternateEmail />,
        perform: () => {
          window.open(`mailto:${linkTo.email}`, "_blank");
        },
      },
      {
        id: "external-twitter",
        parent: "external",
        name: "Twitter",
        keywords: "twitter",
        icon: <Twitter />,
        perform: () => {
          window.open(linkTo.twitter, "_blank");
        },
      },
    );

    if (user.isSignedIn) {
      actions.push(
        {
          id: "discord",
          parent: "external",
          name: "Discord",
          subtitle: "Exclusive server for Plus subscribers",
          keywords: "discord",
          icon: <DiscordIcon titleAccess={null} />,
          perform: () => {
            window.open(linkTo.discord, "_blank");
          },
        },
        {
          id: "user-settings",
          name: "User Settings",
          subtitle: "Change your account settings (display name and password)",
          keywords: "account email password substack subscription",
          icon: <Settings />,
          parent: CMD_K.parents.preferences,
          perform: () => {
            void navigate(routeInternal.settings.to);
          },
        },
        {
          id: "logout",
          name: "Log Out",
          keywords: "logout signout sign singout sing bye",
          icon: <Logout />,
          parent: CMD_K.parents.preferences,
          perform: () => {
            user.signOut();
          },
        },
      );

      if (user.hasAnyRole(...roleParams.admin)) {
        actions.push({
          id: "nav-admin",
          name: "Admin Page",
          keywords: "user users log logs cms",
          icon: <AdminPanelSettings />,
          section: "Admin Tools",
          priority: Priority.LOW,
          perform: () => {
            void navigate(routeInternal.admin.to);
          },
        });
      }
      if (isRealAdmin && user.user) {
        actions.push({
          id: "fake-roles",
          name: "Set fake roles",
          section: "Admin Tools",
          subtitle:
            user.user.fakeRoles === undefined
              ? undefined
              : `Current fake roles: ${
                  user.user.fakeRoles.length === 0
                    ? "no special roles (normal user)"
                    : user.user.fakeRoles.join(", ")
                }`,
          priority:
            user.user.fakeRoles === undefined ? Priority.LOW : Priority.HIGH,
          icon: <Shield />,
          perform: () => {
            setIsFakeRolesDialogOpen(true);
          },
        });
        if (user.user.fakeRoles !== undefined) {
          actions.push({
            id: "clear-fake-roles",
            name: "Clear fake roles",
            keywords: "remove delete",
            priority: Priority.HIGH,
            section: "Admin Tools",
            icon: <ShieldMoon />,
            perform: () => {
              storeFakeRoles({
                user,
                fakeRoles: [],
                removeFakeRoles: true,
              });
            },
          });
        }
      }
    }

    return actions;
  }, [isFullSidebar, isRealAdmin, menuSections, navState, user]);
  useRegisterActions(commandMenuActions, [commandMenuActions]);

  const searchEnabled = user.isSignedIn;
  const inlineSearchEnabled = searchEnabled && !isProDomain;

  const btnSize =
    navState.navigation > ProNavigationState.SMALL ? "large" : "small";

  const btnPadding =
    navState.navigation > ProNavigationState.SMALL ? null : "0.7em";

  const btnCollapsedStyle: SxProps<Theme> = {
    display: "block",
    paddingY: btnPadding,
    fontSize: "12px",
    textAlign: "center",
    justifyContent: "center",
    overflow: "hidden",
    textOverflow: "hidden",
  };

  const btnSx: SxProps<Theme> =
    navState.navigation === ProNavigationState.FULL ? {} : btnCollapsedStyle;
  const btnNavSx: SxProps<Theme> = {
    "&:hover": {
      backgroundColor: (theme) => theme.palette.primary.dark,
    },
  };

  return (
    <SidePanelContainer
      state={navState.navigation}
      isMobile={navState.isMobile}
    >
      <SideNavHeader>
        <SideNavHeaderContent />
      </SideNavHeader>
      <Grid
        container
        gap={1}
        wrap="nowrap"
        justifyContent="space-between"
        padding="1rem"
      >
        <>
          {!navState.isMobile && (
            <Grid item>
              <Tooltip title={isFullSidebar ? "Collapse" : "Expand"}>
                <Button
                  color="nav"
                  variant="outlined"
                  size="small"
                  sx={{
                    ...btnNavSx,
                    minWidth: "unset",
                    height: "100%",
                    borderColor: searchBorderColor,
                  }}
                  onClick={() => {
                    navState.toggleNavigation();
                  }}
                >
                  {navState.navigation === ProNavigationState.FULL ? (
                    <KeyboardDoubleArrowLeft />
                  ) : (
                    <KeyboardDoubleArrowRight />
                  )}
                </Button>
              </Tooltip>
            </Grid>
          )}

          {searchEnabled && (isProDomain || !isFullSidebar) && (
            <Grid item>
              <Tooltip title="Search Game">
                <Button
                  color="nav"
                  variant="outlined"
                  size="small"
                  sx={{
                    ...btnNavSx,
                    minWidth: "unset",
                    height: "100%",
                    borderColor: searchBorderColor,
                  }}
                  {...(inlineSearchEnabled
                    ? {}
                    : {
                        href: routeInternalPro.search.to,
                        component: MuiComponentLink,
                      })}
                  onClick={(e) => {
                    if (inlineSearchEnabled) {
                      setSearchAnchorEl(e.currentTarget);
                    }
                  }}
                >
                  <Search />
                </Button>
              </Tooltip>
              {inlineSearchEnabled && (
                <Popover
                  open={Boolean(searchAnchorEl)}
                  anchorEl={searchAnchorEl}
                  onClose={() => {
                    setSearchAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  elevation={1}
                >
                  <div css={{ width: "15rem", padding: "1rem" }}>
                    <SearchBoxPlus label="Search Game" />
                  </div>
                </Popover>
              )}
            </Grid>
          )}
          {inlineSearchEnabled && isFullSidebar && (
            <Grid item flex="1">
              <SearchBoxPlus
                label={isFullSidebar ? "Search Game" : <Search />}
                InputLabelProps={{
                  sx: {
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  },
                }}
                InputProps={{
                  sx: {
                    color: () =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.contrastText,
                    "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: searchBorderColor,
                      },
                    "& .MuiSvgIcon-root": {
                      fill: searchBorderColor,
                    },
                  },
                }}
              />
            </Grid>
          )}
        </>
      </Grid>

      <SidePanelContent ref={scrollRef} onScroll={handleScroll}>
        <Stack gap={2} marginBottom={4}>
          {menuSections.map((section) => {
            return (
              <Fragment key={section.title}>
                {section.title && (
                  <div>
                    <SideNavTitle full={isFullSidebar}>
                      {section.title.toUpperCase()}:
                    </SideNavTitle>
                    {section.subtitle && (
                      <SideNavSubtitle full={isFullSidebar}>
                        {section.subtitle}
                      </SideNavSubtitle>
                    )}
                  </div>
                )}

                <ButtonContainer>
                  {section.items.map((item) => (
                    <Button
                      key={item.title}
                      color="nav"
                      variant="contained"
                      size={btnSize}
                      sx={{
                        ...btnSx,
                        ...btnNavSx,
                      }}
                      component={AnyLink}
                      to={item.to}
                    >
                      {item.icon}
                      <ButtonLabel full={isFullSidebar}>
                        {!isFullSidebar && item.shortTitle !== undefined
                          ? item.shortTitle
                          : item.title}
                      </ButtonLabel>
                    </Button>
                  ))}
                </ButtonContainer>
              </Fragment>
            );
          })}
        </Stack>

        {user.user && (
          <SidePanelFooter>
            <SideNavTitle full={isFullSidebar}>ACCOUNT:</SideNavTitle>
            <SideNavUserinfo full={isFullSidebar}>
              {user.user.displayName && (
                <>
                  {user.user.displayName}
                  <br />
                </>
              )}
              {user.user.email && user.user.email !== user.user.displayName && (
                <span css={{ fontSize: "0.8em" }}>{user.user.email}</span>
              )}

              {isRealAdmin && (
                <Stack gap={2}>
                  {user.user.fakeRoles !== undefined && (
                    <Alert severity="warning">You have fake roles!</Alert>
                  )}
                  <details css={{ fontSize: "0.8em" }}>
                    <summary>Admin tools</summary>
                    <Button
                      variant="contained"
                      size="small"
                      css={{ textTransform: "none" }}
                      onClick={() => {
                        setIsFakeRolesDialogOpen(true);
                      }}
                    >
                      Set fake roles
                    </Button>

                    <FakeRolesDialog
                      open={isFakeRolesDialogOpen}
                      onClose={() => {
                        setIsFakeRolesDialogOpen(false);
                      }}
                    />
                  </details>
                </Stack>
              )}
            </SideNavUserinfo>

            <ButtonContainer>
              {isProDomain && user.hasAnyRole(["admin"]) && (
                <Button
                  color="secondary"
                  variant="contained"
                  size={btnSize}
                  sx={btnSx}
                  component={Link}
                  to={routeInternalPro.adminCms.to}
                >
                  <AdminPanelSettings />
                  <ButtonLabel full={isFullSidebar}>CMS</ButtonLabel>
                </Button>
              )}
              {!isProDomain && (
                <>
                  {user.hasAnyRole(["admin"]) && (
                    <Button
                      color="secondary"
                      variant="contained"
                      size={btnSize}
                      sx={btnSx}
                      component={Link}
                      to={routeInternal.admin.to}
                    >
                      <AdminPanelSettings />
                      <ButtonLabel full={isFullSidebar}>Admin</ButtonLabel>
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    variant="contained"
                    size={btnSize}
                    sx={btnSx}
                    component={Link}
                    to={routeInternal.settings.to}
                  >
                    <Settings />
                    <ButtonLabel full={isFullSidebar}>Settings</ButtonLabel>
                  </Button>
                </>
              )}

              <Button
                color="warning"
                variant="contained"
                size={btnSize}
                sx={btnSx}
                component="a"
                onClick={() => {
                  user.signOut();
                }}
              >
                <Logout />
                <ButtonLabel full={isFullSidebar}>Logout</ButtonLabel>
              </Button>
            </ButtonContainer>
          </SidePanelFooter>
        )}

        <FooterLinksSection>
          <FooterLinksRow full={isFullSidebar}>
            <FooterLink to={linkTo.companyDomain}>Company</FooterLink>
            <FooterLink to={linkTo.newsletterDomain}>Newsletter</FooterLink>
          </FooterLinksRow>
          <FooterLinksRow full={isFullSidebar}>
            <FooterLink to={linkTo.twitter}>Twitter</FooterLink>
            {user.isSignedIn && (
              <FooterLink to={linkTo.discord}>
                Plus-exclusive Discord
              </FooterLink>
            )}
          </FooterLinksRow>
          <FooterLinksRow full={isFullSidebar}>
            <FooterLink to={`mailto:${linkTo.email}`}>
              {isFullSidebar ? "Send feedback" : "Feedback"}
            </FooterLink>
          </FooterLinksRow>
          <FooterLinksRow full={isFullSidebar}>
            <FooterText>
              &copy; {new Date().getFullYear()} Game Discovery Now, LLC
            </FooterText>
          </FooterLinksRow>
          <FooterLinksRow full={isFullSidebar}>
            <div>
              <Tooltip title="Set system theme">
                <IconButton
                  size="small"
                  color={colorMode === "system" ? "info" : "inherit"}
                  onClick={() => setColorMode("system")}
                >
                  <DesktopMac />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Set light theme">
                <IconButton
                  size="small"
                  color={colorMode === "light" ? "warning" : "inherit"}
                  onClick={() => setColorMode("light")}
                >
                  <LightMode />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Set dark theme">
                <IconButton
                  size="small"
                  color={colorMode === "dark" ? "warning" : "inherit"}
                  onClick={() => setColorMode("dark")}
                >
                  <DarkMode />
                </IconButton>
              </Tooltip>
            </div>
          </FooterLinksRow>
        </FooterLinksSection>
      </SidePanelContent>
    </SidePanelContainer>
  );
};

type MenuItem = {
  title: string;
  shortTitle?: string;
  to: string;
  icon: React.ReactNode;
  /** @default true */
  enabled?: boolean;
};

type MenuSection = {
  title: string | null;
  subtitle?: string;
  /** @default true */
  enabled?: boolean;
  items: MenuItem[];
};

function getMenuSectionsPlus(user: User): MenuSection[] {
  return [
    {
      title: null,
      items: [
        {
          title: "Home",
          to: routeInternal.home.to,
          icon: <Home />,
        },
        {
          title: "Log In",
          to: routeInternal.login.to,
          icon: <Login />,
          enabled: !user.isSignedIn,
        },
        {
          title: "Favorite Apps",
          shortTitle: "Favorites",
          to: routeInternal.favoriteApps.to,
          icon: <Favorite />,
          // Show only if the user is signed in
          enabled: user.hasAnyRole(...featureRoles.favoriteApps),
        },
        {
          title: "Console (Beta)",
          shortTitle: "Console",
          to: baseUrlProduction.pro,
          icon: <VideogameAsset />,
          enabled: user.hasAnyRole(...roleParams.pro),
        },
      ],
    },
    {
      title: "Exclusive Features",
      items: [
        // Demo

        {
          title: "Trending Games",
          shortTitle: "Trending",
          to: routeInternal.trending.to.replace(":page", "games"),
          icon: <TrendingUp />,
          enabled: getMenuRoles(user, ...featureRoles.trending.games),
        },

        // Critical Reflex

        {
          title: "Critical Reflex Trending",
          shortTitle: "Crit. R. Trends",
          to: routeInternal.trending.to.replace(":page", "critical-reflex"),
          icon: <TrendingUp />,
          enabled: getMenuRoles(
            user,
            ...featureRoles.trending["critical-reflex"],
          ),
        },

        // Focus

        {
          title: "All Trending Pre-Releases",
          shortTitle: "All Trends",
          to: routeInternal.trending.to.replace(":page", "all"),
          icon: <TrendingUp />,
          enabled: getMenuRoles(user, ...featureRoles.trending.all),
        },
        {
          title: "Focus Trending Pre-Releases",
          shortTitle: "Focus Trends",
          to: routeInternal.trending.to.replace(":page", "focus"),
          icon: <FilterCenterFocus />,
          enabled: getMenuRoles(user, ...featureRoles.trending.focus),
        },
        {
          title: "Studios to Watch",
          shortTitle: "Studios",
          to: routeInternal.studiosToWatch.to,
          icon: <Visibility />,
          enabled: getMenuRoles(user, ...featureRoles.studiosToWatch),
        },
        {
          title: "Hype Annotator",
          shortTitle: "Annotator",
          to: routeInternal.hypeAnnotator.to,
          icon: <Flag />,
          enabled: getMenuRoles(user, ...featureRoles.hypeAnnotator),
        },

        // Haveli

        {
          title: "Company Hit List",
          shortTitle: "Hit List",
          to: routeInternal.companyHitList.to,
          icon: <MilitaryTech />,
          enabled: getMenuRoles(user, ...featureRoles.companyHitList),
        },
        {
          title: "Haveli Trending Pre-Releases",
          shortTitle: "Haveli Trends",
          to: routeInternal.trending.to.replace(":page", "haveli"),
          icon: <TrendingUp />,
          enabled: getMenuRoles(user, ...featureRoles.trending.haveli),
        },

        // Nocturne

        {
          title: "Nocturne Data Set",
          shortTitle: "Noct. Data",
          to: routeInternal.nocturne.to,
          icon: <BarChart />,
          enabled: getMenuRoles(user, ...featureRoles.nocturne),
        },

        // Playtonic

        {
          title: "Playtonic Trending",
          shortTitle: "Playtonic Trends",
          to: routeInternal.trending.to.replace(":page", "playtonic"),
          icon: <TrendingUp />,
          enabled: getMenuRoles(user, ...featureRoles.trending.playtonic),
        },

        // PQube

        {
          title: "PQube Trending",
          shortTitle: "PQube Trends",
          to: routeInternal.trending.to.replace(":page", "pqube"),
          icon: <TrendingUp />,
          enabled: getMenuRoles(user, ...featureRoles.trending.pqube),
        },
      ],
    },
    {
      title: "Steam Deep Dive",
      items: [
        {
          title: "Game Affinity Explorer",
          shortTitle: "Affinity",
          to: routeInternal.affinityExplorer.to,
          icon: <JoinFull />,
          enabled: getMenuRoles(user, ...featureRoles.affinityExplorer),
        },
        {
          title: "Country Explorer",
          shortTitle: "Countries",
          to: routeInternal.countryExplorer.to,
          icon: <MapIcon />,
          enabled: getMenuRoles(user, ...featureRoles.countryExplorer),
        },
        {
          title: "Sales Explorer",
          shortTitle: "Sales",
          to: routeInternal.salesExplorer.to,
          icon: <MonetizationOn />,
          enabled: getMenuRoles(user, ...featureRoles.salesExplorer),
        },
        {
          title: "Trending Tag Ranking",
          shortTitle: "Trending Tags",
          to: routeInternal.trendingTagRanking.to,
          icon: <Tag />,
          enabled: getMenuRoles(user, ...featureRoles.trendingTagRanking),
        },
        {
          title: "Hype Conversion Tags",
          shortTitle: "Hype Conversion",
          to: routeInternal.hypeConversionTagRanking.to,
          icon: <Loyalty />,
          enabled: getMenuRoles(user, ...featureRoles.hypeConversionTagRanking),
        },
      ],
    },
    {
      title: "Tools",
      items: [
        {
          title: "Browse Games",
          shortTitle: "Browse",
          to: routeInternal.browse.to,
          icon: <Search />,
          enabled: getMenuRoles(user, ...featureRoles.browseGames),
        },
        {
          title: "Steam Release Heatmap",
          shortTitle: "Heatmap",
          to: routeInternal.heatmap.to,
          icon: <LocalFireDepartment />,
          enabled: getMenuRoles(user, ...featureRoles.releaseHeatmap),
        },
        {
          title: "Compare Games",
          shortTitle: "Compare",
          to: routeInternal.compare.to,
          icon: <Compare />,
          enabled: getMenuRoles(user, ...featureRoles.compare),
        },
        {
          title: "Revenue Predictor",
          shortTitle: "Revenue",
          to: routeInternal.revenuePredictor.to,
          icon: <Paid />,
          enabled: getMenuRoles(user, ...featureRoles.revenuePredictor),
        },
        {
          title: "Publisher / Developer",
          shortTitle: "Pub / Dev",
          to: routeInternal.publisherDeveloper.to,
          icon: <Business />,
          enabled: getMenuRoles(user, ...featureRoles.publisherDeveloper),
        },
      ],
    },
    {
      title: "Pre-Release",
      items: [
        {
          title: "Steam Hype Chart",
          shortTitle: "Hype",
          to: routeInternal.hype.to,
          icon: <Whatshot />,
          enabled: getMenuRoles(user, ...featureRoles.hypeChart),
        },
        {
          title: "Top Games (Follower Velocity)",
          shortTitle: "Follower Velocity",
          to: routeInternal.followerVelocity.to,
          icon: <Speed />,
          enabled: getMenuRoles(user, ...featureRoles.followerVelocity),
        },
        {
          title: "Top Wishlists Chart",
          shortTitle: "Wishlists",
          to: routeInternal.wishlists.to,
          icon: <CardGiftcard />,
          enabled: getMenuRoles(user, ...featureRoles.topWishlists),
        },
        {
          title: "Tag Ranking",
          to: `${routeInternal.tagRanking.to}?t.filter[0].classification.is=Genre&t.sort[0].median=desc`,
          icon: <LocalOffer />,
          enabled: getMenuRoles(user, ...featureRoles.preReleaseTagRanking),
        },
      ],
    },
    {
      title: "Post-Release",
      items: [
        {
          title: "Tag Explorer",
          shortTitle: "Tags",
          to: routeInternal.tagExplorer.to,
          icon: <LocalOffer />,
          enabled: getMenuRoles(user, ...featureRoles.tagExplorer),
        },
        {
          title: "Monthly (New Games)",
          shortTitle: "Monthly",
          to: routeInternal.postReleaseMonthly.to,
          icon: <CalendarMonth />,
          enabled: getMenuRoles(user, ...featureRoles.postRelease),
        },
        {
          title: "Weekly (New Games)",
          shortTitle: "Weekly",
          to: routeInternal.postReleaseWeekly.to,
          icon: <ViewWeek />,
          enabled: getMenuRoles(user, ...featureRoles.postRelease),
        },
        // {
        //   title: "Monthly (All Games)",
        //   shortTitle: "Monthly",
        //   to: routeInternal.monthlyAll.to,
        //   icon: <CalendarMonth />,
        //   enabled: getMenuRoles(user, ...featureRoles.allSales),
        // },
        // {
        //   title: "Weekly (All Games)",
        //   shortTitle: "Weekly",
        //   to: routeInternal.weeklyAll.to,
        //   icon: <ViewWeek />,
        //   enabled: getMenuRoles(user, ...featureRoles.allSales),
        // },
        {
          title: "Monthly Most Reviewed",
          shortTitle: "Monthly",
          to: routeInternal.mostReviewed.to,
          icon: <Reviews />,
          enabled: getMenuRoles(user, ...featureRoles.mostReviewed),
        },
        {
          title: "CCU Rankings",
          shortTitle: "CCU",
          to: routeInternal.ccuRankings.to,
          icon: <Groups2 />,
          enabled: getMenuRoles(user, ...featureRoles.ccuRankings),
        },
      ],
    },
    {
      title: "Other Charts",
      subtitle: "updated regularly",
      items: [
        {
          title: "US Switch Charts",
          shortTitle: "US Switch",
          to: routeInternal.switch.to,
          icon: <NintendoIcon titleAccess={null} />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartSwitch),
        },
        {
          title: "US Xbox Charts",
          shortTitle: "US Xbox",
          to: routeInternal.xbox.to,
          icon: <XboxIcon titleAccess={null} />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartXbox),
        },
        {
          title: "US PlayStation Charts",
          shortTitle: "US PlayStation",
          to: routeInternal.playstation.to,
          icon: <PlaystationIcon titleAccess={null} />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartPlaystation),
        },
        {
          title: "Epic Top Sellers",
          shortTitle: "Epic",
          to: routeInternal.epicTopSellers.to,
          icon: <EpicIcon titleAccess={null} />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartEpic),
        },
        {
          title: "Global Quest Charts",
          shortTitle: "Meta Quest",
          to: routeInternal.meta.to,
          icon: <MetaIcon titleAccess={null} />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartMeta),
        },
        {
          title: "US Apple Arcade Charts",
          shortTitle: "Apple Arcade",
          to: routeInternal.apple.to,
          icon: <AppleIcon titleAccess={null} />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartAppleArcade),
        },
        {
          title: "Fortnite Top Maps",
          shortTitle: "Fortnite",
          to: routeInternal.fortniteTopMaps.to,
          icon: <MapIcon />,
          enabled: getMenuRoles(user, ...featureRoles.manualChartFortnite),
        },
      ],
    },
    {
      title: "Other",
      items: [
        {
          title: "eBook Downloads",
          shortTitle: "eBooks",
          to: routeInternal.ebooks.to,
          icon: <Book />,
          enabled: getMenuRoles(user, ...featureRoles.ebooks),
        },
        {
          title: "Pitch Decks",
          shortTitle: "Pitch Decks",
          to: routeInternal.pitchdecks.to,
          icon: <CoPresent />,
          enabled: getMenuRoles(user, ...featureRoles.pitchdecks),
        },
        {
          title: "Changelog",
          shortTitle: "Changelog",
          to: routeInternal.changelog.to,
          icon: <ChangeHistory />,
          enabled: getMenuRoles(user, ...featureRoles.changelogSidePanel),
        },
      ],
    },
  ];
}

function getMenuSectionsConsole(user: User): MenuSection[] {
  if (!user.hasAnyRole(ROLE_DEFS.pro)) {
    return [
      {
        title: null,
        items: [
          {
            title: "Log In",
            to: routeInternal.login.to,
            icon: <Login />,
            enabled: !user.isSignedIn,
          },
          {
            title: "Steam Data",
            shortTitle: "Steam",
            to: baseUrlProduction.plus,
            icon: <SteamIcon titleAccess={null} />,
          },
        ],
      },
    ];
  }

  return [
    {
      title: "Platform Explorer",
      items: [
        {
          title: "All Formats",
          shortTitle: "All",
          to: routeInternalPro.allFormats.to,
          icon: <VideogameAsset />,
        },
        {
          title: "Xbox",
          to: routeInternalPro.xbox.to,
          icon: <XboxIcon titleAccess={null} />,
        },
        {
          title: "PlayStation",
          to: routeInternalPro.playstation.to,
          icon: <PlaystationIcon titleAccess={null} />,
        },
      ],
    },
    {
      title: "Data Deep Dives",
      items: [
        {
          title: "Browse Games",
          shortTitle: "Browse",
          to: routeInternal.browse.to,
          icon: <Search />,
        },
        {
          title: "Publisher / Developer",
          shortTitle: "Pub / Dev",
          to: routeInternalPro.publisherDeveloper.to,
          icon: <Business />,
        },
        {
          title: "Game Affinity Explorer",
          shortTitle: "Affinity",
          to: routeInternalPro.affinityExplorer.to,
          icon: <JoinFull />,
        },
        {
          title: "Country Explorer",
          shortTitle: "Countries",
          to: routeInternalPro.countryExplorer.to,
          icon: <MapIcon />,
        },
        {
          title: "Tag Explorer",
          shortTitle: "Tags",
          to: routeInternalPro.tagExplorer.to,
          icon: <LocalOffer />,
        },
        {
          title: "DAU / MAU Rankings",
          shortTitle: "DAU / MAU",
          to: routeInternalPro.dauRankings.to,
          icon: <Groups2 />,
        },
      ],
    },
    {
      title: "Steam Hype",
      enabled: user.hasAnyRole(["admin"]),
      items: [
        {
          title: "Most Hyped Games",
          shortTitle: "Games",
          to: routeInternalPro.mostHypedGames.to,
          icon: <RocketLaunch />,
        },
        {
          title: "Top Genres by Hype",
          shortTitle: "Genres",
          to: routeInternalPro.topGenresByHype.to,
          icon: <Topic />,
        },
      ],
    },
    {
      title: "Other",
      items: [
        {
          title: "GameDiscoverCo Plus",
          shortTitle: "Plus",
          to: baseUrlProduction.plus,
          icon: <SteamIcon titleAccess={null} />,
        },
      ],
    },
    {
      title: "Admin Only",
      enabled: user.hasAnyRole(["admin"]),
      items: [
        {
          title: "Pro Insight",
          to: routeInternalPro.insight.to,
          icon: <TipsAndUpdates />,
        },
        {
          title: "Switch",
          to: routeInternalPro.cmsPage.to.replace(":slug", "switch"),
          icon: <NintendoIcon titleAccess={null} />,
        },
        {
          title: "Pro Research Reports",
          shortTitle: "Reports",
          to: routeInternalPro.cmsPage.to.replace(
            ":slug",
            "pro-research-reports",
          ),
          icon: <Science />,
        },
      ],
    },
  ];
}

function getMenuSections(user: User): MenuSection[] {
  const subdomain = process.env.GATSBY_SUBDOMAIN;
  switch (subdomain) {
    case "plus": {
      return getMenuSectionsPlus(user);
    }
    case "pro": {
      return getMenuSectionsConsole(user);
    }
    default: {
      return [];
    }
  }
}

/**
 * Decide whether to show a menu item based on the user's roles
 */
function getMenuRoles(user: User, ...params: Parameters<User["hasAnyRole"]>) {
  const hasRole = user.hasAnyRole(...params);

  // Show the menu item if the value is set to `roleParams.allSignedInUsers`
  // and the user is signed in
  if (isEqual(params, roleParams.allSignedInUsers)) {
    return true;
  }

  return hasRole;
}

const SidePanelContainer = styled("div")<{ state: number; isMobile: boolean }>`
  width: ${(props) =>
    props.state === ProNavigationState.FULL
      ? getFullWidth(props.isMobile)
      : "7.5rem"};
  background-color: ${th.sidebarBg};
  height: 100%;
  display: ${(props) =>
    props.state === ProNavigationState.HIDDEN ? "none" : "flex"};
  position: fixed;
  box-sizing: border-box;
  flex-direction: column;
  transition: width 0.2s ease-in-out;
`;
const SidePanelContent = styled("div")`
  padding: 0 1rem;
  justify-content: start;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
`;
const SidePanelFooter = styled("div")(
  ({ theme }) => `
  padding: 1.25rem 1rem 1rem 1rem;
  color: white;
  background-color: ${theme.palette.nav.main};
  justify-content: end;
  margin-top: auto;
  box-shadow: 0 -0.625rem 0.3125rem -2px #11113366;
`,
);
const FooterLinksSection = styled("div")`
  padding: 0.75rem 1rem 1rem 1rem;
  color: ${th.textMutedMoreContrast};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;
const FooterLinksRow = styled("div")<{ full: boolean }>`
  display: flex;
  text-align: ${(props) => (props.full ? "left" : "center")};
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-direction: ${(props) => (props.full ? "row" : "column")};
`;
const FooterText = styled("span")({
  fontSize: "0.75rem",
});
const FooterLink = styled(MutedLink)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.text.disabled,
}));
const SideNavTitle = styled("span")<{ full: boolean }>`
  text-align: ${(props) => (props.full ? "left" : "center")};
  font-weight: bold;
  color: #eeeeee;
  font-size: ${(props) => (props.full ? "1rem" : "0.8125rem")};
`;
const SideNavSubtitle = styled("span")<{ full: boolean }>(
  ({ theme, full }) => ({
    color: theme.palette.text.disabled,
    fontSize: "0.75rem",
    marginLeft: "0.25rem",
    display: full ? "inline-block" : "block",
  }),
);
const SideNavUserinfo = styled("div")<{ full: boolean }>`
  font-size: ${(props) => (props.full ? "1rem" : "0.8125rem")};
  line-break: anywhere;
  padding-top: 1em;
  padding-bottom: 1em;
`;
const ButtonContainer = styled("div")`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
  a {
    text-transform: none;
    justify-content: flex-start;
  }
  a.MuiButton-sizeSmall {
    padding-left: 0;
    padding-right: 0;
  }
`;
const ButtonLabel = styled("div")<{ full: boolean }>`
  margin-left: ${(props) => (props.full ? "0.625rem" : "0")};
`;
const SideNavHeader = styled("div")(
  ({ theme }) => `
  width: 100%;
  background-color: ${theme.palette.nav.main};
  height: 4.375rem;
`,
);

const SideNavHeaderContent = () => {
  const navState = useNavigationState();

  const badgeTitle = isProDomain ? "🎮" : subdomainName;

  return (
    <Link
      to={routeInternal.home.to}
      css={{
        textDecoration: "none",
        overflow: "hidden",
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      {navState.navigation !== ProNavigationState.HIDDEN && (
        <>
          <img
            src={logo}
            alt="GameDiscoverCo Logo"
            style={{
              height: "100%",
              ...(navState.navigation === ProNavigationState.FULL
                ? {}
                : {
                    display: "inline-block",
                    verticalAlign: "top",
                  }),
            }}
          />
          <HeaderText
            navigationState={navState.navigation}
            aria-label="GameDiscoverCo"
          >
            {match(SPECIAL_DAY)
              .with(null, () => "GameDiscoverCo")
              .otherwise((special) => {
                const node = (
                  <Tooltip
                    title={
                      <>
                        Special day: {special.name}
                        <br />
                        <br />
                        {special.description}
                        <br />
                        <br />
                        Emoji provided by{" "}
                        <ExternalLink href="https://twemoji.twitter.com/">
                          Twemoji
                        </ExternalLink>{" "}
                        by Twitter.
                        <br />
                        License:{" "}
                        <ExternalLink href="https://creativecommons.org/licenses/by/4.0/">
                          CC-BY 4.0
                        </ExternalLink>
                      </>
                    }
                  >
                    <img
                      src={special.image}
                      alt={`${special.name} emoji`}
                      css={{ height: "0.6em" }}
                    />
                  </Tooltip>
                );

                const parts = [..."GameDiscoverCo"] as ReactNode[];
                parts.splice(special.position, 1, node);

                return jsxJoin(parts, "", false);
              })}
          </HeaderText>
          <HeaderBadge navigationState={navState.navigation}>
            {badgeTitle}
          </HeaderBadge>
        </>
      )}
    </Link>
  );
};
const HeaderText = styled("h2")<{ navigationState: number }>`
  color: white;
  letter-spacing: 0;
  display: ${(props) =>
    props.navigationState === ProNavigationState.FULL ? "flex" : "none"};
  align-items: baseline;
  flex-wrap: nowrap;
`;
const HeaderBadge = styled("h3")<{ navigationState: number }>`
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  height: 2rem;
  background-color: white;
  font-size: 1.125rem;
  border-radius: 4px;
  color: black;
  margin-left: ${(props) =>
    props.navigationState === ProNavigationState.FULL
      ? "0.625rem"
      : "0.3125rem"};
`;

/* eslint-disable @typescript-eslint/no-magic-numbers -- Dates for special logos */
/**
 * Special day logos for holidays and other events
 */
const SPECIAL_DAY = match(new Date())
  .returnType<{
    name: string;
    description: ReactNode;
    /** Where to place the logo in the `GameDiscoverCo` text. Zero-based index. */
    position: number;
    image: string;
  } | null>()
  .when(
    () => {
      // Don't show special logos in initial render as the date may be stale
      return !isBrowserEnvironment;
    },
    () => null,
  )
  .when(
    (d) => d.getMonth() === 1 && d.getDate() === 14,
    () => ({
      name: "Valentine's Day",
      description: "You love data, don't you?",
      position: -1,
      image: emojiValentines,
    }),
  )
  .when(
    (d) => d.getMonth() === 2 && d.getDate() === 10,
    () => ({
      name: "Mario Day",
      description: "It's-a me, Mario!",
      position: 5,
      image: emojiMario,
    }),
  )
  .when(
    (d) => d.getMonth() === 3 && d.getDate() === 1,
    () => ({
      name: "April Fool's Day",
      description: "Where's Half-Life 3, GabeN?",
      position: 2,
      image: emojiAprilFools,
    }),
  )
  .when(
    (d) => d.getMonth() === 3 && d.getDate() === 22,
    () => ({
      name: "Earth Day",
      description:
        "Go touch some grass! Or just look at some data. We won't judge.",
      position: 5,
      image: emojiEarthDay,
    }),
  )
  .when(
    (d) => d.getMonth() === 4 && d.getDate() === 1,
    () => ({
      name: "International Workers' Day",
      description: "All (game) workers should unionize!",
      position: -1,
      image: emojiWorkersDay,
    }),
  )
  .when(
    (d) => d.getMonth() === 5 && d.getDate() === 28,
    () => ({
      name: "Stonewall Riots",
      description: "Happy Pride Month! Stonewall was a riot.",
      position: 5,
      image: emojiPride,
    }),
  )
  .when(
    (d) => d.getMonth() === 9 && d.getDate() === 31,
    () => ({
      name: "Halloween",
      description: "It's the spookiest time of the year!",
      position: -1,
      image: emojiHalloween,
    }),
  )
  .when(
    (d) => d.getMonth() === 10 && d.getDate() === 26,
    () => ({
      name: "Thanksgiving",
      description: "We're thankful for data. And you!",
      position: 5,
      image: emojiThanksgiving,
    }),
  )
  .when(
    (d) => d.getMonth() === 11 && d.getDate() >= 20,
    () => ({
      name: "Xmas",
      description: "Merry Xmas! We bring you gifts of data.",
      position: -1,
      image: emojiXmas,
    }),
  )
  .when(
    (d) =>
      (d.getMonth() === 0 && d.getDate() === 1) ||
      (d.getMonth() === 11 && d.getDate() === 31),
    () => ({
      name: "New Year",
      description: "Happy New Year! Our resolution is to bring you more data.",
      position: -1,
      image: emojiNewYear,
    }),
  )
  .otherwise(() => null);
/* eslint-enable @typescript-eslint/no-magic-numbers */

export default SidePanel;
